<template>
    <v-container fill-height fluid>
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="3">
                <v-card class="elevation-5 pa-0">
                    <v-toolbar color="primary" dark>
                        <v-spacer />
                        <v-toolbar-title>Transplant Active Admin Login</v-toolbar-title>
                        <v-spacer />
                    </v-toolbar>
                    <v-form
                        ref="form"
                        v-model="valid"
                        @submit.prevent="loginSubmit">
                        <v-card-text class="pb-0 mt-2">
                            <v-text-field
                                v-model="emailAddress"
                                outlined
                                label="Email Address"
                                prepend-icon="mdi-account"
                                type="text"
                                :rules="emailAddressRules"
                                required 
                                autofocus />
                            <v-text-field
                                v-model="password"
                                outlined
                                label="Password"
                                prepend-icon="mdi-lock"
                                type="password"
                                :rules="passwordRules"
                                required />
                        </v-card-text>
                        <v-divider />
                        <v-card-actions class="mx-3 py-4">
                            <app-button
                                outlined
                                @click="forgotPassword">
                                Forgot Password
                            </app-button>   
                            <v-spacer />
                            <app-button
                                type="submit"
                                :disabled="loggingIn"
                                :loading="loggingIn">
                                Log In
                            </app-button>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    components: {
        appButton: () => import("@/components/AppButton"),
    },

    data() {
        return {
            valid: true,
            password: "",
            emailAddress: "",
            passwordRules: [(v) => !!v || "Password is required"],
            emailAddressRules: [
                (v) => !!v || "Email Address is required",
                (v) =>
                    !(v && v.length > 320) ||
                    "Email Address must be less than 320 characters",
            ],
        };
    },
    computed: {
        ...mapState({
            loggingIn: s => s.authentication.loggingIn
        })
    },
    methods: {
        ...mapActions({
            login: "authentication/login"
        }),
        async loginSubmit() {
            this.$refs.form.validate();
            if (!this.valid) {
                return;
            }

            const { emailAddress, password } = this;
            await this.login({ emailAddress, password });
        },
        forgotPassword() {
            this.$router.push("/request-reset-password");
        }
    }
};
</script>
